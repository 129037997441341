<template>
  <div>
    <div class="title">人才库</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
      </div>
    </div>

    <div class="interviewers_item" v-for="item in resumeList" :key="item.id">
      <div class="interviewers_item_post">应聘：{{ item.job.name }}</div>
      <div class="interviewers_item_info">
        <el-image
          class="interviewers_item_info_avatar"
          :src="item.image"
          fit="cover"
        ></el-image>
        <div class="interviewers_item_info_data">
          <div class="interviewers_item_info_data_name">
            {{ item.realname }}
          </div>
          <div class="interviewers_item_info_data_box">
            <div>{{ item.degree }}</div>
            <el-divider direction="vertical"></el-divider>
            <div>{{ item.age }}岁</div>
            <el-divider direction="vertical"></el-divider>
            <div>{{ item.phone }}</div>
          </div>
          <div class="interviewers_item_info_data_exp">
            <div>
              工作经历:
              <div v-for="(item, index) in item.jobExpDup" :key="index">
                <p>
                  <span>{{ item.company }}</span>
                  <span>{{ item.department }}</span>
                  <span>{{ item.position }}</span>
                  <span>{{ item.industry }}</span>
                </p>
                <p>
                  <span>{{ item.entry_date }} 到 {{ item.leave_date }}</span>
                </p>
                <p>
                  <span>{{ item.work_desc }}</span>
                </p>
              </div>
            </div>
            <div>
              教育经历：
              <div v-for="(item, index) in item.eduExpDup" :key="index">
                <p>
                  <span>{{ item.school }}</span>
                  <span>{{ item.degree }}</span>
                  <span>{{ item.major }}</span>
                </p>
                <p>
                  <span>{{ item.entry_date }} 到 {{ item.leave_date }}</span>
                </p>
                <p>
                  <span>{{ item.work_desc }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="interviewers_item_btn">
          <div class="custom_button plain no_select">添加到候选人</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      search: {
        keyword: "",
      },
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      resumeList: [],
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("recruitment", ["getjobResumeList"]),

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword;
      }
      return () => {
        params.page = this.page.page;
        let loading = this.$loading();
        this.getjobResumeList(params)
          .then((res) => {
            console.log(res);
            this.resumeList = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            this.$nextTick(() => {
              loading.close();
            });
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }
}

.interviewers_item {
  margin: 20px 0;
  border: 1px solid #f0f4f8;
  border-radius: 5px;
  min-height: 200px;

  .interviewers_item_post {
    height: 40px;
    background-color: #f5f7fa;
    border-radius: 5px 5px 0px 0px;
    font-size: 14px;
    color: #1c2438;
    line-height: 40px;
    padding: 0 25px;
  }

  .interviewers_item_info {
    display: flex;

    .interviewers_item_info_avatar {
      flex-shrink: 0;
      margin: 25px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    .interviewers_item_info_data {
      width: 100%;
      padding: 25px 0;

      .interviewers_item_info_data_name {
        font-size: 20px;
        font-weight: bold;
        color: #1c2438;
      }

      .interviewers_item_info_data_box {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #495060;
        margin: 10px 0;
      }

      .interviewers_item_info_data_exp {
        display: flex;

        > div {
          flex: 1;
          font-size: 14px;
          color: #495060;

          > div {
            > p {
              > span:not(:last-child) {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .interviewers_item_btn {
      display: flex;
      margin: 25px;

      .custom_button {
        width: 106px;
        height: 32px;
        line-height: 32px;

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
